<template>
  <div class="container">
    <table-page dataName="whitelist"
                :search="search"
                ref="table"
                :tabList="tabList"
                @on-success="fetchSuccess"
                :params="{activity_id:$route.query.id}"
                :request="fetchWhites">
      <template slot="button">
        <el-button type="primary"
                   class="mr-15"
                   @click="$refs.white.showModal($route.query.id)">添加号码</el-button>
        <FileUpload action="/api/mall/admin/public/importWhitelist"
                    class="mr-15"
                    @on-success="fileUploadSuccess"
                    :params="{activity_id:$route.query.id}"
                    title="导入白名单" />
        <el-button icon="el-icon-download"
                   class="mr-15"
                   @click="downloadWhite">下载白名单模板</el-button>
      </template>
      <el-table-column prop="tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="created_at"
                       show-overflow-tooltip
                       label="创建时间">
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="primary"
                     icon="el-icon-edit"
                     size="small"
                     @click="$refs.white.showModal($route.query.id,scope.row)"
                     circle></el-button>
          <el-button type="danger"
                     size="small"
                     icon="el-icon-delete"
                     @click="delWhite(scope.row)"
                     circle></el-button>
        </template>
      </el-table-column>
    </table-page>
    <SetWhite ref="white"
              @on-success="$refs.table.fetch()" />
  </div>
</template>

<script>
import TablePage from '@/components/TablePage'
import FileUpload from '@/components/FileUpload'
import SetWhite from './components/SetWhite'
import { fetchWhites, delWhite } from '@/api/market'
export default {
  name: 'PublicWhites',
  data () {
    return {
      exportUrl: '',
      fetchWhites,
      search: [{
        type: 'input',
        name: '手机号码',
        placeholder: '请输入手机号码',
        key: 'tel',
        value: ''
      }],
      tabList: [{
        value: '',
        label: '全部'
      }, {
        value: 'PENDING',
        label: '未提交'
      }, {
        value: 'RECEIVED',
        label: '已提交'
      }]
    }
  },
  components: {
    TablePage,
    FileUpload,
    SetWhite
  },
  methods: {
    fileUploadSuccess (res) {
      if (res.meta.code === 0) {
        if (res.data.status === 'SUCCESS') {
          this.$alert('导入成功')
          this.$refs.table.fetch()
        } else {
          this.$alert(res.data.err_msg, '导入情况')
        }
      } else {
        this.$alert(res.meta.msg)
      }
    },
    downloadWhite () {
      window.open(`${process.env.VUE_APP_BASE_URL}/data/envelope/%E6%96%B0%E5%85%AC%E5%BC%80%E6%B4%BE%E5%88%B8%E7%99%BD%E5%90%8D%E5%8D%95%E6%A8%A1%E6%9D%BF.xlsx`, '_blank')
    },
    exportData () {
      window.open(this.exportUrl, '_blank')
    },
    addStore () {
      this.$router.push({
        name: 'SetStore'
      })
    },
    setStore (id) {
      this.$router.push({
        name: 'SetStore',
        query: {
          id: id
        }
      })
    },
    delWhite (e) {
      this.$confirm('确认删除', {
        type: 'warning'
      }).then(async () => {
        const res = await delWhite({
          activity_id: this.$route.query.id,
          id: e.id
        })
        if (res.meta.code === 0) {
          this.$notify({
            title: '成功',
            message: '删除成功',
            type: 'success'
          })
          this.$refs.table.fetch()
        }
      }).catch(() => {

      })
    },
    fetchSuccess (e) {
      this.exportUrl = e.export_url
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
</style>
